export function formatIngredients(ingredients, cocktails){
  ingredients = ingredients.map(i => {
         
    let drinks = cocktails.filter(c => { 
      return doesInclude(c.ingredients, i.name);         
    })

    i.name = i.name.split(' ').map(i=>{
      let lower = i;
      return i.charAt(0).toUpperCase() + lower.substr(1);
    }).join(' ');

    i.cocktails = drinks;
    return i;
  })

  return ingredients;
}

function doesInclude(array, item){
      
  array = array.join('|').toLowerCase().split('|');

  if(array.includes(item) === true ){
    return true;
  } else {
    return false
  }
}