import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {connect} from 'react-redux';
import { setCocktails } from '../actions/cocktails';


import Rating from './Rating';
import { replace } from 'lodash';

class Cocktails extends Component {
  constructor(props){
    super(props);

    this.state = {
      cocktails: []
    }
   
  }

  replaceSpacesWithDashes(string){
    return string.replace(/\s+/g, '-');
  }

  render(){
  
    return (
      <div className="App">      
        {this.props.cocktails.map( (cocktail, index) => {
          let name = this.replaceSpacesWithDashes(cocktail.name);
          return (
            <Link to={{
              pathname: (`/cocktail/${cocktail.id}/${name}`),
              state: {cocktail: cocktail}
            }} key={index} class="cocktail-link">
              <div className="cocktail-container">
                <h3>{cocktail.name}</h3>
                <img src={cocktail.image} />
                <Rating rating={cocktail.rating} />
              </div>
            </Link>
          )
        })
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    setCocktails: (cocktails)=>{
      dispatch(setCocktails(cocktails));
    }
  }
}

const mapStateToProps = (state )=>{
  return{
    cocktails: state.cocktails.cocktails,
  }
  
}

export default connect(mapStateToProps, mapDispatchToProps )(Cocktails);
