import React, { Component } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';


import Ingredient from './Ingredient-Input';

class Submission extends Component {

  constructor(props){
    super(props);

    this.state = {
      value: '',
      ingredients: [<Ingredient />],
      preparation: null
    }

    this.addInputs = this.addInputs.bind(this);

  }


  addInputs(e){

    let parent = e.target.parentElement;
    let inputs = parent.childNodes[0].cloneNode(true);
    
  
    for(var i=0; i < inputs.childNodes.length; i++){
      inputs.childNodes[i].value = ''
      if(inputs.childNodes[i].name==="garnish" || inputs.childNodes[i].name==="optional" ){
        inputs.childNodes[i].value="false";
      }
    }
    parent.insertBefore(inputs, parent.childNodes[parent.childNodes.length-2]);

    let count = document.querySelectorAll('.step').length;
   
    this.setState({
      preparation: count
    })

  }

  addIngredients(e){
   let ingredients = this.state.ingredients;
   ingredients.push(
      <Ingredient />
   )
        
    this.setState({
      ingredients
    })
  }

  removeInput(e){
    let parent = e.target.parentNode;
      parent.removeChild(parent.getElementsByTagName("FIELDSET")[parent.getElementsByTagName("FIELDSET").length - 1]);
  }


  loadFile(event) {
    var output = document.getElementById('output');
    output.src = URL.createObjectURL(event.target.files[0]);
  };


  render() {
    return (
      <div class="wrapper">
        
        <div className="row submission"> 
             
          <form method="POST" action="http://api.cocktailpro.app/submission/web" encType="multipart/form-data">
          <div style={{textAlign: 'center'}}>
            <img src="/assets/img/cocktail-pro-light-scaled.png" style={{height: 200, padding: 25}} />
            <h1 style={{fontWeight: 300}}>Recipe Submission</h1>
            <p>Have a tasty cocktail recipe you'd love to share with the world? Share your amazing recipe by filling out the form below.</p>
          </div>
            <div>
              <input type="text" className="form-control" name="name" placeholder="Drink Name" />
            </div>
            <div>
              <fieldset class="preparation" name="preparation">
                <input name="preparation" className="form-control equipment"  placeholder="Glassware" />
              </fieldset>
            </div>
            <div>
              <textarea name="description" className="form-control"  placeholder="Description" />
            </div>
            <div>                        
              <img id="output"/>
              <div class="image">                
                <input type="file" name="image" id="image" accept="image/*" className="inputfile" onChange={(e)=>this.loadFile(e)}  />
                <label style={{display: 'block', fontWeight: 'bold'}} for="image">Select an Image</label>
              </div>
            </div>

            <div className="ingredient-container">            
              {this.state.ingredients.map(i => {
                return i
              })}    
              <span onClick={(e)=>this.addIngredients(e)}><FaPlus /> Add Ingredient</span>
              {this.state.ingredients.length > 1 ? <span onClick={(e)=>this.removeInput(e)}><FaMinus /> Remove Ingredient</span> : null}
    
            </div>
            <div >
              <fieldset className="tags" name="tags">
                <input name="steps" className="step" placeholder="Preparation Step" />
              </fieldset>
              <span onClick={(e)=>this.addInputs(e)}><FaPlus /> Add Preparation Step</span>
               {this.state.preparation > 0 ? <span onClick={(e)=>this.removeInput(e)}><FaMinus /> Remove Preparation Step</span> : null}
            </div>
            <input style={{width: '100%'}} className="btn btn-primary" type="submit" value="Submit" />
          </form>
        </div>
      </div>
    );
  }
}

export default Submission;
