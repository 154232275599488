import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";


import Rating from './Rating';

class IngredientCocktails extends Component {
  constructor(props){
    super(props);

    this.state = {
      cocktails: []
    }    
  }

  componentDidMount(){    
    let ingredient = this.props.match.params.ingredient;
      let cocktails = this.props.cocktails;
  
     
  
      let filter = cocktails.filter(c=> {
        c.ingredients = c.ingredients.map(i => {
          return i.toLowerCase();
        })
  
        if(c.ingredients.includes(ingredient)){
          return c;
  
        }
      })
  
      console.log(filter);
  
      this.setState({
        cocktails: filter,
        ingredient:  this.props.match.params.ingredient
      })
    }
  

  componentDidUpdate(prevProps){
    if(prevProps != this.props){      
      let ingredient = this.props.match.params.ingredient.toLowerCase();
      let cocktails = this.props.cocktails;
      
    
      let filter = cocktails.filter(c=> {
        c.ingredients = c.ingredients.map(i => {
          return i.toLowerCase();
        })
  
        if(c.ingredients.includes(ingredient)){
          return c;
  
        }
      })
  
      console.log(filter);
  
      this.setState({
        cocktails: filter,
        ingredient:  this.props.match.params.ingredient
      })
    }
   
  }


  render(){
  
    return(
      this.state ?
        <div>
          <h1 class="ingredient-heading">Cocktails Containing {this.state.ingredient}</h1>
          <div className="App">          
            {this.state.cocktails.map( (cocktail, index) => {
              return (
                <Link to={{
                  pathname: `/cocktail/${cocktail.id}/${encodeURI(cocktail.name)}`,
                }} key={index}>
                  <div className="cocktail-container">
                    <h3>{cocktail.name}</h3>
                    <img src={cocktail.image} />
                    <Rating rating={cocktail.rating} />
                  </div>
                </Link>
              )        
            })}
          </div>
        </div>
        :  null
    )

  }
}

const mapStateToProps = (state )=>{
  return{
    cocktails: state.cocktails.cocktails,
  } 
}

export default connect(mapStateToProps)(IngredientCocktails);