import React, { Component } from 'react';
import ShoppingItems from './ShoppingItems';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class Shop extends Component {
  constructor(props){
    super(props);    
    console.log(props);
  }


  render(){
    if(this.props.products.hasOwnProperty("barSets") && this.props.products.hasOwnProperty("glassware")){
      return(
        <div className="shopping-wrapper">
          <div class="shopping-category-container">
            {this.props?.products?.barSets && this.props?.products?.barSets[0] ?
              <Link to="/shop/bar-sets">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props?.products?.barSets[0] ? this.props.products?.barSets[0]?.LargeImage[0]?.URL[0] : null} />
                  </div>
                  <h3>Bar Sets</h3>   
                </div>  
              </Link>         
            : null}

          {this.props?.products?.mixers [0] ?
            <Link to="/shop/mixers">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props?.products?.mixers[0] ? this.props?.products?.mixers[0]?.LargeImage[0]?.URL[0] : null} />
                  </div>
                  <h3>Mixers</h3>   
                </div>   
            </Link>        
            : null}

           {this.props?.products?.glassware?.whiskey[0] ?
              <Link to="/shop/glassware">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props.products.glassware.whiskey[0] ? this.props?.products?.glassware?.whiskey[0]?.LargeImage[0]?.URL[0] : null} />
                  </div>
                  <h3>Glassware</h3>   
                </div>    
              </Link>
            : null}

            {this.props.products?.shakers[0] ?
             <Link to="/shop/shakers">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props.products?.shakers[0] ? this.props.products?.shakers[0]?.LargeImage[0]?.URL[0] : null} />
                  </div>
                  <h3>Shakers</h3>   
                </div>     
              </Link>
              : null}
          </div>
          <h1 style={{textAlign: 'center'}}>Bar Products</h1>
          
          <div class="shopping-container">
            {this.props?.products?.barSets && this.props.products.barSets.length > 0 ? <ShoppingItems items={this.props.products.barSets} /> : null}
            {this.props?.products?.mixers && this.props.products.mixers.length > 0 ? <ShoppingItems items={this.props.products.mixers} /> : null}
            {this.props?.products?.shakers && this.props.products.shakers.length > 0 ? <ShoppingItems items={this.props.products.shakers} /> : null}
            {this.props.products?.glassware?.collins && this.props.products.glassware.collins.length > 0 ? <ShoppingItems items={this.props.products.glassware.collins} /> : null}
            {this.props.products?.glassware?.coupe && this.props.products.glassware.coupe.length > 0 ? <ShoppingItems items={this.props.products.glassware.coupe} /> : null}
            
            {this.props.products?.glassware?.martini && this.props.products.glassware.martini.length > 0 ? <ShoppingItems items={this.props.products.glassware.martini} /> : null}
            {this.props.products?.glassware?.shot && this.props.products.glassware.shot.length > 0 ? <ShoppingItems items={this.props.products.glassware.shot} /> : null}
            {this.props.products?.glassware?.whiskey && this.props.products.glassware.whiskey.length > 0 ? <ShoppingItems items={this.props.products.glassware.whiskey} /> : null}
          </div>
        </div>
      )
    } else {
      return null;
    }
      
  }
}

const mapStateToProps = (state )=>{
  return{
    cocktails: state.cocktails.cocktails,
    products: state.products.products
  }
  
}

export default connect(mapStateToProps)(Shop);