import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

class Nav extends Component {
  constructor(props){
    super(props);

  }

  render(){
    return(      
      <nav className="nav">
        <Link to="/">All Cocktails</Link>
        <Link to="/ingredients">Ingredients</Link>        
   
      </nav>
    )

  }
}

export default Nav;