import React, { Component } from 'react';
import {connect} from 'react-redux';
import {FaStar, FaStarHalfAlt } from 'react-icons/fa';




class Rating extends Component {
  constructor(props){
    super(props);

  }
  
  render() {

    let rating = this.props.rating ? this.props.rating.average : 0;

    let stars  = [];

    for(let i = 1; i <= 5; i++){
      if(i <= rating){
        stars.push(<FaStar className="star" color={'gold'} style={{border: 1, borderColor: '#ccc'}} />);
      } 

      else if(i > rating && i - rating > .75){
        stars.push(
          <FaStar color={'#CCC'} className="star" style={{border: 1, borderColor: '#ccc'}} />
        );
      } else {
        stars.push(
          <FaStarHalfAlt color={'gold'} className="star" style={{border: 1, borderColor: '#ccc'}}  />         
        );
      }
    }

    return (
      <div className="rating" style={{flex: 1, flexDirection: 'row', marginBottom: 0, justifyContent: 'center'}} >
        {stars}    
         <span style={{zIndex: 1}}>({this.props.rating ? this.props.rating.count : 0})</span>
      </div>
    )
  }

}



const mapStateToProps = (state )=>{
  
  return{
    cocktails: state.cocktails.cocktails,
    search: state.search.search
  }
  
  
}

export default connect(mapStateToProps)(Rating);
