import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

class Ingredients extends Component {
  constructor(props){
    super(props);
  }

  render(){
    let ingredients = this.props.ingredients.sort( (a,b) => {
      return b.cocktails.length - a.cocktails.length;
    })
    
    return(
      
      <div className="ingredients">
         <div>
            { 
              ingredients.map(i=>{
                return (<li><Link to={`/ingredients/${i.name.toLowerCase()}`}>{i.name}</Link> <span>({i.cocktails.length} cocktails)</span></li>)
              }) 
            }
        </div>
      </div>
     
    )

  }
}

const mapStateToProps = (state )=>{
  return{
    cocktails: state.cocktails.cocktails,
    ingredients: state.ingredients.ingredients
  }
  
}



export default connect(mapStateToProps)(Ingredients);