import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import StructuredData from 'react-google-structured-data';
import Rating from './Rating';
import Helmet from 'react-helmet';
import ShareButton from 'react-social-share-buttons'
import Products from '../data/products';

const products = [
  {
    "id": 1,
    "name": "24oz Cocktail Shaker Bar Set",
    "url": "https://amzn.to/476tra8",
    "img": "https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08PY683LR&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=guishe-20&language=en_US",
    "price": "$16.99"
  },
  {
    "id": 2,
    "name": "10-Piece Bar Tool Set",
    "url": "https://amzn.to/49q22l7",
    "img": "https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07P8MYXS5&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=guishe-20&language=en_US",
    "price": "$32.99"
  },
  {
    "id": 3,
    "name": "30pcs Mixology Bartender Kit with Stand",
    "url": "https://amzn.to/3MCYLoE",
    "img": "https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0BHHS1WZT&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=guishe-20&language=en_US",
    "price": "24.99"
  }
]

class Cocktail extends Component {
  constructor(props){
    super(props);
  }

  replaceDashesWithSpace(string){
    return string.replace(/-/g, ' ');
  }


  componentDidMount(){    
    let encodedURI = this.replaceDashesWithSpace(this.props.match.params.name);
    console.log(encodedURI)
          
    fetch(`https://api.cocktailpro.app/cocktail/${this.props.match.params.id}/${encodedURI}` )
      .then(data=>{
        return data.clone().json();
      })
      .then(data=>{        
        this.setState({
          cocktail: data
        })   
      })
      .catch(err => {
        console.log(err)
      })

      this.findSimilar = this.findSimilar.bind(this);
  }

  componentDidUpdate(prevProps){
    window.scrollTo(0, 0);
    if(this.props.match.params.name != prevProps.match.params.name){      
      let encodedURI = encodeURIComponent(this.props.match.params.name)
 
      fetch(`https://api.cocktailpro.app/cocktail/${this.props.match.params.id}/${encodedURI}` )
        .then(data=>{
          return data.clone().json();
        })
        .then(data=>{                          
          this.setState({
            cocktail: data
          })
          return;
        }).then( ()=>{
          this.findSimilar();          
        })

    } 




  }

  findSimilar(data){
    let cocktail = data || this.state.cocktail;
    let cocktails = this.props.cocktails;
    let similarCocktails = [];
    let similarCount = 0;


      let addSimilar = (percentage) => {
        for(var i=0; i < cocktails.length; i++ ){

          if(similarCount === 6){
            break;
         }

  
        let count = cocktail.ingredients.filter( val => {
          if(cocktail.name !== cocktails[i].name){
            cocktails[i].ingredients = cocktails[i].ingredients.map(c =>{
              return c.toLowerCase()
            });

            return cocktails[i].ingredients.indexOf(val.toLowerCase()) != -1;
          } else {
            return false;
          }
            
        })
  
        if( (count.length / cocktail.ingredients.length ) > percentage){
            similarCocktails.push(cocktails[i]);
            similarCount++;
        }
      }    
      
      return similarCocktails;

    }

    let similar = addSimilar(.40);

    if(similarCount < 1){
      addSimilar(.25);
    }
  
    this.setState({
      similar: similar,
    });
  }

  render(){
    if(this.state && this.props.cocktails.length > 0){
      if(!this.state.similar){
        this.findSimilar(this.state.cocktail);
      }
      let ingredients = this.state.cocktail.ingredients.map( (ingredients, index) =>{
        return (
            `${this.state.cocktail.amount[index] ? this.state.cocktail.amount[index] : '' }` + 
            `${this.state.cocktail.unit[index] ? ' ' + this.state.cocktail.unit[index] + ' ' : ''}` +
            `${this.state.cocktail.ingredients[index] }` 

        )
      });
  
      let steps = this.state.cocktail.steps.map(step => {
        return({
          "@type": 'HowToStep',
          "text": `${step}`
        })
      })
      
      if(this.props.products.glassware && this.props.products.shakers){
        var randomNum = randomNum = Math.floor(Math.random() * (this.props.products.shakers.length - 0) + 0);
        console.log(randomNum)
      }

                  
      let script = document.createElement('script');
      script.src = "///z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US";          
      script.async = true;
      document.body.appendChild(script);
      
     
      return (
        <div class="max-width">
          <Helmet>
          <title>{this.state.cocktail.name} - Cocktail Pro</title>
            <meta name="description" content={'Learn how to mix a ' + this.state.cocktail.name + ' cocktail on Cocktail Pro. Discover hundreds of new cocktail recipes. Download the Cocktail Pro app on the App Store and Google Play. '} />
            <meta property="og:title" content={this.state.cocktail.name} />
            <meta property="og:url" content={window.location} />
            <meta property="og:image" content={this.state.cocktail.image} />
            
            <script type="text/javascript">
              amzn_assoc_placement = "adunit0";
              amzn_assoc_tracking_id = "cocktail-pro-20";
              amzn_assoc_ad_mode = "search";
              amzn_assoc_ad_type = "smart";
              amzn_assoc_marketplace = "amazon";
              amzn_assoc_region = "US";
              amzn_assoc_default_search_phrase = "cocktail kits";
              amzn_assoc_default_category = "All";
              amzn_assoc_linkid = "335dae4beb9aef6d42ec49568f2f6dc2";
              amzn_assoc_design = "in_content";
            </script>        
         
          </Helmet>
          <StructuredData
              type='Recipe'
              data={{
                  name: `${this.state.cocktail.name}`,
                  image: `${this.state.cocktail.image}`,
                  author: {
                    "@type": 'person',
                    "name": 'Cocktail Pro'
                  },
                  datePublished: '2019-01-03',
                  description: this.state.cocktail.description ? `${this.state.cocktail.decription}` : '',
                  keywords: `${this.state.cocktail.name}`,
                  recipeCategory: 'Cocktail',
                  recipeIngredient: ingredients,
                  recipeInstructions: steps,
                  aggregateRating: {
                    "@type": 'AggregateRating',
                    "ratingValue": this.state.cocktail.rating ? `${this.state.cocktail.rating.average}` : "5",
                    "ratingCount": this.state.cocktail.rating ? `${this.state.cocktail.rating.sum}` : "1",

                  }
              }}
          />          
          <div className="cocktail">      
            <div className="cocktail-row"> 
              <div className="cocktail-details">
                <h1>{this.state.cocktail.name}</h1>
                <h2>Glassware</h2>
                <p>{this.state.cocktail.preparation}</p>
                <div>
                  <h2>Ingredients</h2>           
                  {this.state.cocktail.ingredients.map( (ingredients, index) =>{
                    return (
                      <p key={index}>
                        <li>
                          {this.state.cocktail.amount[index] ? this.state.cocktail.amount[index] + ' ' : null } 
                          {this.state.cocktail.unit[index] ? ' ' + this.state.cocktail.unit[index] + ' ' : null} 
                          {this.state.cocktail.ingredients[index] } 
                        </li>
                      </p>
                    )
                  })}
                    
                </div>
                <div>
                  <h2>Preparation Steps</h2>
                  {this.state.cocktail.steps.map( (step, index) => {
                    return <p key={index}>{step}</p>
                  })}
                </div>
                <div class="social">
                <ShareButton
                  compact
                  socialMedia={'facebook'}
                  url={window.location}
                  media={this.state.cocktail.name}
                  text={
                    this.state.cocktail.name + ' Cocktail Recipe. Made with' + 
                        this.state.cocktail.ingredients.map( (i, index) => {
                     if(index < this.state.cocktail.ingredients.length - 1 && i != ''){
                       return ' ' + i;
                     } else {
                       return ' and ' + i + "."
                     }
                  })} 
                 />    
                 <ShareButton
                  compact
                  socialMedia={'twitter'}
                  url={window.location}
                  media={this.state.cocktail.image}
                  text={
                    this.state.cocktail.name + ' Cocktail Recipe. Made with' + 
                        this.state.cocktail.ingredients.map( (i, index) => {
                     if(index < this.state.cocktail.ingredients.length - 1 && i != ''){
                       return ' ' + i;
                     } else {
                       return ' and ' + i + "."
                     }
                  })} 
                 />   
                 <ShareButton
                  compact
                  socialMedia={'pinterest'}
                  url={window.location}
                  media={this.state.cocktail.image}
                  text={
                    this.state.cocktail.name + ' Cocktail Recipe. Made with' + 
                        this.state.cocktail.ingredients.map( (i, index) => {
                     if(index < this.state.cocktail.ingredients.length - 1 && i != ''){
                       return ' ' + i;
                     } else {
                       return ' and ' + i + "."
                     }
                  })} 
                 />                        
                </div>
                <div style={{marginTop: 20}}>
                  <a  href="/submission"><button className="submission-link">Add a Cocktail Recipe</button></a>
                </div>  
              </div>
              <div className="cocktail-image-container">                         
                  <img src={this.state.cocktail.image} className="cocktail-image" />
                  <Rating rating={this.state.cocktail.rating} />                    
                </div>                                   
              </div>  
             
        </div>          

        <h2 className="shopping-header">Mixologist Products </h2>
        <div className="shopping-container">      
          {products.map( (product, index) => {
            return(
              <div className="shopping-item">
                <h3>{product.name}</h3>
                <a href={product.url} target="_blank">
                  <img src={product.img} />
                </a>
                <p className="shopping-price">{product.price}</p>
              </div>
            )
          })}
        </div>

        <div className="similar">    
        {this.state.similar && this.state.similar.length > 0 ?                        
            <h2>Similar Cocktails</h2>  : null
          }                                       
          <div class="similar-wrapper">                
            {this.state.similar && this.state.similar.length > 0 ? this.state.similar.map( (c, index) => {
                return (              
                    <Link to={{
                      pathname: `/cocktail/${c.id}/${encodeURI(c.name)}`,
                      state: {cocktail: c}
                    }} key={index}>
                      <div className="cocktail-container">
                        <h3>{c.name}</h3>
                        <img src={c.image} />
                        <Rating rating={c.rating} />
                      </div>
                    </Link>              
                )
              }) : null }
          </div>
        </div> 
          
      </div>
      )
    } else {
      return (
        <div className="cocktail">
        </div>
      );
    }
  
  }
}

const mapStateToProps = (state ) => {
  return{
    cocktails: state.cocktails.cocktails,
    products: state.products.products
  }
  
}

export default connect(mapStateToProps)(Cocktail);