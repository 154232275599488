import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import { setCocktails } from '../actions/cocktails';
import {setSearch, setSearchTerm} from '../actions/search';


class Search extends Component {
  constructor(props){
    super(props);

    this.state = {
      value: ''
    }
  }

  filterSearch(e){
    this.props.setSearchTerm(e.toLowerCase());
    
    let nameResults = this.props.cocktails.filter(cocktail => {
      return cocktail.name.toLowerCase().includes(e.toLowerCase()) || cocktail.ingredients.includes(e.toLowerCase());
    })

    let ingredientResults = []
     this.props.cocktails.map(cocktail => {
       if(nameResults.includes(cocktail)){
         return cocktail;
       }

       let included = cocktail.ingredients.filter(i => {
          return i.toLowerCase().includes(e.toLowerCase())
       })

       if(included.length > 0){
         ingredientResults.push(cocktail);
       }
    })
    
    let results = nameResults.concat(ingredientResults);

    this.props.setSearch(results);
   
  }

  render(){
    return (
      <div className="searchContainer">
        {this.props.cocktails ? 
          <Autocomplete
            getItemValue={(item) => item.name}
            items={this.props.search}
            renderItem={(item, isHighlighted) =>
             
                <div style={{ display: 'flex', background: isHighlighted ? 'lightgray' : '#fff', alignItems: 'center' }}>
                             
                      <img src={item.image} style={{height: 50, width: 50, marginRight: 15}} /> {item.name}              
                  
                </div>
          
            }
            menuStyle={{
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              background: 'rgba(255, 255, 255, 0.9)',
              padding: '2px 0',
              fontSize: '90%',
              position: 'fixed',
              overflow: 'auto',
              maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
              zIndex: 9999
            }}
            value={this.props.searchTerm}
            onChange={(e) => this.filterSearch(e.target.value)}
            onSelect={(val, item) =>  {
              this.props.history.push(`/cocktail/${item.id}/${item.name}`)}}
            inputProps={{className: 'search', placeHolder: 'Search Cocktails'}}
          />
          :
          null
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    setCocktails: (cocktails)=>{
      dispatch(setCocktails(cocktails));
    },
    setSearch: (cocktails)=>{
      dispatch(setSearch(cocktails));
    },
    setSearchTerm: (term)=>{
      dispatch(setSearchTerm(term));
    }
  }
}

const mapStateToProps = (state )=>{
  return{
    cocktails: state.cocktails.cocktails,
    search: state.search.search,
    searchTerm: state.search.searchTerm
  }
  
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
