import React, { Component } from 'react';

class ShoppingItems extends Component {
  constructor(props){
    super(props);
    
  }

  render(){
    if(this.props.items && this.props.items.length > 0){
      return this.props.items.map(item => {
        if(item && item.ASIN && item.LargeImage && item.DetailPageURL && item.ItemAttributes && item.DetailPageURL && item.OfferSummary){
          return( 
            <a href={item.DetailPageURL[0]} className="shopping-item" key={item.ASIN[0]} target="_BLANK">
              <div class="shopping-image">
                <img src={item.LargeImage[0].URL[0] ? item.LargeImage[0].URL[0] : null} />
              </div>
              <div class="shopping-details">
                <h4>{item.ItemAttributes[0].Title[0]}</h4>
                <p class="shopping-price">{
                  (item &&
                  item.OfferSummary && 
                  item.OfferSummary[0] &&
                  item.OfferSummary[0].LowestNewPrice &&
                  item.OfferSummary[0].LowestNewPrice[0] &&
                  item.OfferSummary[0].LowestNewPrice[0].FormattedPrice &&
                  item.OfferSummary[0].LowestNewPrice[0].FormattedPrice[0])
                  ? item.OfferSummary[0].LowestNewPrice[0].FormattedPrice[0] 
                  : ''}</p>
              </div>          
            </a>
          )
        }
      })
    } else {
      return null
    }
  }   
}

export default ShoppingItems;