import { combineReducers } from 'redux'
import cocktails from './cocktails';
import search from './search';
import ingredients from './ingredients';
import products from './products';

export default combineReducers({
  cocktails,
  search,
  ingredients,
  products
})