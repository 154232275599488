import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import '../Hamburger.css';

class Hamburger extends React.Component {

  render () {
    return (
      <Menu left>
        <Link to="/">All Cocktails</Link>
        <Link to="/ingredients">Ingredients</Link>
        <Link to="/shop">Shop</Link>
      </Menu>
    );
  }
}

export default Hamburger;