let initialState = {
  cocktails: []
}

const cocktails = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COCKTAILS':
      return Object.assign({}, state, {
        cocktails: action.payload
      })
     
     default:
      return state;
  }
}

export default cocktails;
