import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { GoogleLogin } from 'react-google-login';

class Footer extends Component {
  constructor(props){
    super(props);

  }

  responseGoogle = (response) => {
    console.log(response);
  }

  render(){
    return(
      <div>
        <GoogleLogin
            clientId="880153163605-ndr2f8261o2hlc54or7jdrh4k3r6jmsk.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
          />
      </div>
    )

  }
}

export default Footer;