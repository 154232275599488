import React, { Component } from 'react';
import ReactGA from 'react-ga';

class Footer extends Component {
  constructor(props){
    super(props);

  }

  render(){
    return(
      <footer>
        <div className="footer-logo">
          <img src="/assets/img/cocktail-pro-light-scaled.png" />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <ReactGA.OutboundLink
                eventLabel="App_Store_Click"
                to="https://itunes.apple.com/us/app/cocktail-pro-drink-recipes/id1445509858?ls=1&mt=8"
                target="_blank">
                <img alt='Get it on the Apple App Store' src='/assets/img/app-store-badge.png' style={{maxHeight: 50, width: 'auto'}} />
              </ReactGA.OutboundLink>
              <ReactGA.OutboundLink
                eventLabel="Play_Store_Click"
                to="https://play.google.com/store/apps/details?id=com.stithholdings.cocktailpro&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank">
                  <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style={{maxHeight: 75, width: 'auto'}} />
              </ReactGA.OutboundLink>    
           </div>
      </footer>
    )

  }
}

export default Footer;