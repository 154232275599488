let initialState = {
  search: []
}

const search = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return Object.assign({}, state, {
        search: action.payload
      })

    case 'SET_SEARCH_TERM':
      return Object.assign({}, state, {
        searchTerm: action.payload
      })
     
     default:
      return state;
  }
}

export default search;
