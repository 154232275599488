import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import ShoppingItems from './ShoppingItems';

class Product extends Component {
  constructor(props){
    super(props);

  }

  componentDidMount(){
    let product = this.props.match.params.product;

    switch (product){
      case 'bar-sets':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Bar Sets'
        });
        break;
      case 'glassware':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Glassware'
        });
        break;
      case 'mixers':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Mixers'
        });
        break;
      case 'shakers':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Shakers'
        });
        break;
      default:
        break;

    }
  }

  componentWillReceiveProps(nextProps){
    let product = nextProps.match.params.product;

    switch (product){
      case 'bar-sets':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Bar Sets'
        });
        break;
      case 'glassware':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Glassware'
        });
        break;
      case 'mixers':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Mixers'
        });
        break;
      case 'shakers':
        this.setState({
          slug: this.props.match.params.product,
          product: 'Shakers'
        });
        break;
      default:
        break;

    }
  }
  
  render(){
    let product = this.props.match.params.product;


    if(this.state && this.props.products.mixers){
      return(
        <div>
          <h1 style={{textAlign: 'center'}}>Shop {this.state.product}</h1>
          <div class="shopping-category-container">
            {this.props.products.barSets[0] ?
              <Link to="/shop/bar-sets">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props.products.barSets[0] ? this.props.products.barSets[0].LargeImage[0].URL[0] : null} />
                  </div>
                  <h3>Bar Sets</h3>   
                </div>  
              </Link>         
            : null}

          {this.props.products.mixers[0] ?
            <Link to="/shop/mixers">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props.products.mixers[0] ? this.props.products.mixers[0].LargeImage[0].URL[0] : null} />
                  </div>
                  <h3>Mixers</h3>   
                </div>   
            </Link>        
            : null}

           {this.props.products.glassware.whiskey[0] ?
              <Link to="/shop/glassware">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props.products.glassware.whiskey[0] ? this.props.products.glassware.whiskey[0].LargeImage[0].URL[0] : null} />
                  </div>
                  <h3>Glassware</h3>   
                </div>    
              </Link>
            : null}

            {this.props.products.shakers[0] ?
             <Link to="/shop/shakers">
                <div class="shopping-category">
                  <div>                     
                    <img src={this.props.products.shakers[0] ? this.props.products.shakers[0].LargeImage[0].URL[0] : null} />
                  </div>
                  <h3>Shakers</h3>   
                </div>     
              </Link>
              : null}
          </div>
          <div class="shopping-container">
            {this.state.product === 'Bar Sets' && this.props.products.barSets && this.props.products.barSets.length > 0 ? <ShoppingItems items={this.props.products.barSets} /> : null}
            {this.state.product === 'Mixers' && this.props.products.mixers && this.props.products.mixers.length > 0 ? <ShoppingItems items={this.props.products.mixers} /> : null}
            {this.state.product === 'Shakers' && this.props.products.shakers && this.props.products.shakers.length > 0 ? <ShoppingItems items={this.props.products.shakers} /> : null}      
            {this.state.product ==='Glassware' && this.props.products.glassware && this.props.products.glassware.collins && this.props.products.glassware.collins.length > 0 ? <ShoppingItems items={this.props.products.glassware.collins} /> : null}
            {this.state.product === 'Glassware' && this.props.products.glassware && this.props.products.glassware.coupe && this.props.products.glassware.coupe.length > 0 ? <ShoppingItems items={this.props.products.glassware.coupe} /> : null}        
            {this.state.product ==='Glassware' && this.props.products.glassware && this.props.products.glassware.martini && this.props.products.glassware.martini.length > 0 ? <ShoppingItems items={this.props.products.glassware.martini} /> : null}
            {this.state.product === 'Glassware' && this.props.products.glassware && this.props.products.glassware.shot && this.props.products.glassware.shot.length > 0 ? <ShoppingItems items={this.props.products.glassware.shot} /> : null}
            {this.state.product === 'Glassware' && this.props.products.glassware && this.props.products.glassware.whiskey && this.props.products.glassware.whiskey.length > 0 ? <ShoppingItems items={this.props.products.glassware.whiskey} /> : null}
          </div>
        </div>
      )
    } else {
      return null
    }
    

  }
}

const mapStateToProps = (state )=>{
  return{
    cocktails: state.cocktails.cocktails,
    products: state.products.products
  }
  
}

export default connect(mapStateToProps)(Product);