let initialState = {
  ingredients: []
}

const ingredients = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INGREDIENTS':
      return Object.assign({}, state, {
        ingredients: action.payload
      })
     
     default:
      return state;
  }
}

export default ingredients;
