import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";

import Login from './components/Login';
import Cocktails from './components/Cocktails';
import Cocktail from './components/Cocktail';
import Search from './components/Search';
import Nav from './components/Nav';
import Shop from './components/Shop';
import Product from './components/Product';
import Submission from './components/Submission';
import Hamburger from './components/Hamburger';

import Ingredients from './components/Ingredients';
import IngredientCocktails from './components/IngredientCocktails';
import Footer from './components/Footer';

import { setCocktails } from './actions/cocktails';
import { setIngredients } from './actions/ingredients';
import { setProducts } from './actions/products';

import { formatIngredients } from './utils/format';



import './App.css';

ReactGA.initialize('UA-29486176-6');

class App extends Component {
  constructor(props){
    super(props);
  }

  async componentDidMount(){
    await fetch('https://api.cocktailpro.app')
      .then(data=>{      
        return data.json()
      })
      .then(data=>{        
        data = this.setRating(data);
        this.props.setCocktails(data)     
      })

    await fetch('https://api.cocktailpro.app/ingredients')
      .then(data=>{      
        return data.json()
      })
      .then(data=>{  
        data = formatIngredients(data, this.props.cocktails);
        console.log(data);
        this.props.setIngredients(data)     
      })

     await fetch('https://api.cocktailpro.app/products')
      .then(data=>{      
        return data.json()
      })
      .then(data=>{  
        this.props.setProducts(data)     
      })
  }

  setRating(data){
    data.map(d=>{
      if(d.rating){
        return d
      } else {
        d.rating = {
          sum: 0,
          count: 0,
          average: 0
        }
        return d;
      }
    }) 
    return data; 
  }

  fireTracking(){
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    return null;
  }

  render() {
    return (
      <Router onUpdate={this.fireTracking}>

        <div>
          <Helmet>
            <title>Cocktail Recipes - Cocktail Pro</title>
            <meta name="description" content="Search Cocktail Pro's vast collection of cocktail recipes. Search by name or by ingredient. Download the Cocktail Pro app on Android and Apple App Store." />
           
          </Helmet>
          <nav class="primary">
            <Hamburger /> 
            <div className="logo-container">
              <img src="/assets/img/cocktail-pro-light-scaled.png" />
              <Search />  
              <Nav />                                
            </div>
          </nav>
          <main>
            <Route path='/' component={this.fireTracking} />     
            <Switch>     
                          
              <Route exact path="/cocktail/:id/:name" component={Cocktail} /> 
              <Route exact path="/ingredients/:ingredient" component={IngredientCocktails} />   
              <Route exact path="/ingredients" component={Ingredients} />                             
              <Route exact path="/submission" component={Submission} /> 
              <Route exact path="/login" component={Login} />  
              <Route path="/" component={Cocktails} />            
            </Switch>            
          </main>        
          <Footer />  
          <script data-ad-client="ca-pub-2165595222485341" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>        
        </div>

      </Router>

    );
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    setCocktails: (cocktails)=>{
      dispatch(setCocktails(cocktails));
    },
    setIngredients: (ingredients)=>{
      dispatch(setIngredients(ingredients));
    },
    setProducts: (products)=>{
      dispatch(setProducts(products));
    }
  }
}

const mapStateToProps = (state )=>{
  return{
    cocktails: state.cocktails.cocktails,
    ingredients: state.ingredients.ingredients,
    products: state.products.products
  }
  
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
